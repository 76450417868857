<template>
  <div  > 
    <vue-editor   v-model="content" :editorToolbar="customToolbar" id="container" > </vue-editor>
  </div>
</template>

<script>
  import { VueEditor } from "vue2-editor";


export default {
  components: {   VueEditor },
  data() {
    return {
      content: '' ,
      customToolbar:[['bold', 'italic', 'underline'],[{'background':[]},{'color':[]}],  ['image','link'],['strike']],
    }
  },

};
</script>

<style lang="scss">
 
</style>
